<template>
  <client-only>
  <main class="auth__main">
    <form class="auth__form">
      <div
        class="auth__login w-100"
        v-click-outside="
          () => {
            activePopup = false;
          }">
        <div class="registration__group w-100">
          <label for="login" class="auth__label">{{
            $t("auth.enterMailOrPhone")
          }}</label>
          <div
            class="reg__field--block relative"
            :class="{ error: v$.loginField.$error }">
            <input
              type="text"
              id="login"
              v-model.trim="form.loginField"
              class="reg__field"
              autocomplete="off"
              @input="updateRegion" />
            <span v-if="hasStringNumber" class="choose__region">
              <button
                type="button"
                @click="clickOpenPopup()"
                class="active__region">
                <span class="region">
                  <country-flag :country="region" rounded size="normal" />
                </span>
              </button>
            </span>
          </div>
          <div v-if="v$.$invalid && v$.loginField.$error" class="errors">
            <p class="error__text" v-if="!v$.loginField.required">
              {{ $t("auth.requiredToFill") }}
            </p>
            <span v-if="v$.loginField.required">
              <p
                class="error__text"
                v-if="!v$.loginField.email && !hasStringNumber">
                {{ $t("auth.phoneEmail") }}
              </p>
              <p
                class="error__text"
                v-if="!v$.loginField.numeric && hasStringNumber">
                {{ $t("auth.phoneWrong") }}
              </p>
              <p
                class="error__text"
                v-if="!v$.loginField.maxLength && hasStringNumber">
                {{ $t("auth.phoneWrong") }}
              </p>
            </span>
          </div>
        </div>

        <AuthPopupRegion
          :activePopup="activePopup"
          @change-country="onChangeCountry" />
      </div>

      <div class="registration__group">
        <label for="password" class="auth__label" ref="password">{{ $t("auth.password") }}</label>
        <div
          class="reg__field--block relative w-100"
          :class="{ error: v$.password.$error }">
          <input
            type="password"
            v-model="form.password"
            id="password"
            class="reg__field"
            autocomplete="off" />
        </div>

        <div v-if="v$.$invalid && v$.password.$error" class="errors">
          <p class="error__text" v-if="!v$.password.required">
            {{ $t("auth.requiredToFill") }}
          </p>
        </div>
      </div>

      <AuthRemember />
      <AuthSocial v-if="false" />
    </form>
    <div class="auth__footer-btn">
      <button
        type="button"
        class="auth__button w_100-468"
        @click.prevent="onLogin">
        {{ $t("auth.nextButton") }}
      </button>
      <button
        type="button"
        class="auth__button auth__button--forgot"
        @click="emit('recoveryPassword')">
        {{ $t("auth.fogotPassword") }}
      </button>
    </div>
  </main>
  </client-only>
</template>

<script setup>
import AuthRemember from "@/components/Auth/AuthRemember/AuthRemember";
import CountryFlag from "vue-country-flag-next";
import AuthPopupRegion from "@/components/Auth/AuthPopupRegion/AuthPopupRegion";
import AuthSocial from "@/components/Auth/AuthSocial/AuthSocial";

const emit = defineEmits(["onLogin", "changeLoading", "recoveryPassword"]);
const accountModule = useAccount();
import { ref, computed, onMounted } from "vue";
import { auth } from "@/helpers/auth";
const { $doNoty: doNoty } = useNuxtApp();
import { load as loadRecaptcha } from "recaptcha-v3";
import { required, maxLength, email } from "@vuelidate/validators";
const authStore = useAuth();

const region = ref("ru");
const activePopup = ref(false);
const isCurrentTextNumber = ref(true);
const recaptcha = ref(null);
const form = reactive({
  loginField: "",
  password: "",
});
const hasStringNumber = computed(() => /^\+?([0-9]*)\d*$/.test(form.loginField));
const rules = computed(() => {
  if (hasStringNumber.value) {
    return {
      loginField: {
        required,
        maxLength: maxLength(20),
      },
      password: {
        required,
      },
    };
  } else {
    return {
      loginField: {
        required,
        email,
      },
      password: {
        required,
      },
    };
  }
});
import { useVuelidate } from "@vuelidate/core";
const v$ = useVuelidate(rules, form);

onMounted(async () => {
  try {
    const recaptchaValue = await authStore.getRecaptchaSiteKey();
    if (recaptchaValue.use) {
      loadRecaptcha(recaptchaValue.key).then((recaptchaValue) => {
        recaptcha.value = recaptchaValue;
      });
    }
  } catch (err) {
    console.error(err);
  }
});

function updateRegion() {
  const popupStore = usePopup();
  if (!hasStringNumber.value) {
    region.value = "";
    return;
  }

  const regionObj = popupStore.regions.find((item) => {
    return form.loginField
      .replace("+", "")
      .startsWith(item.number.replace("+", ""));
  });
  region.value = regionObj ? regionObj.region : "ru";
}

function onChangeCountry(country) {
  region.value = country.region || "ru";
  form.loginField = country.number;
  activePopup.value = false;
}

function clickOpenPopup() {
  activePopup.value = true;
}
async function onLogin() {
  v$.value.$touch();
  const isValid = await v$.value.$validate();
  if (!isValid) {
    return;
  }
  emit("changeLoading", true);

  const recaptcha = await getRecaptcha();

  const loginData = {
    credential: hasStringNumber.value
      ? form.loginField.replace("+", "")
      : form.loginField,
    password: form.password,
    remember: auth.remember,
    recapthcaToken: recaptcha,
  };

  try {
    const response = await auth.login(loginData);

    if (response) {
      await accountModule.loadUserInfo();

      emit("onLogin");
    }
  } catch(err) {
    console.log("err", [err]);
    doNoty.error(err.msg || "Ошибка авторизации");
  }

  emit("changeLoading", false);
}

async function getRecaptcha() {
  return recaptcha.value ? await recaptcha.value.execute("submit") : true;
}
</script>

<script>
import ClickOutside from "vue-click-outside";
export default {
  name: "AuthForm",
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss" scoped>
.choose__region__on_auth {
  transform: translateY(-50%) !important;
}

.auth__login {
  position: relative;
}
</style>

<style lang="scss">
.auth__login {
  .popup__region {
    top: 100px;
  }
}
</style>
