<template>
  <main class="auth__main">
    <form @submit.prevent class="auth__form">
      <div class="registration__group">
        <label for="name" class="auth__label">{{ $t("auth.yourName") }}</label>
        <div
          class="reg__field--block"
          :class="{ error: v$.name.$error }">
          <input
            type="text"
            id="name"
            v-model="reg_form.name"
            name="name"
            class="reg__field"
            autocomplete="off" />
        </div>
        <div v-if="v$.$invalid && v$.name.$error" class="errors">
          <p class="error__text" v-if="!v$.name.required">
            {{ $t("auth.requiredToFill") }}
          </p>
        </div>
      </div>

      <div class="registration__group" v-if="false">
        <label for="surname" class="auth__label">{{ $t("auth.yourSurname") }}</label>
        <div class="reg__field--block">
          <input
            type="text"
            id="surname"
            v-model="reg_form.surname"
            name="surname"
            class="reg__field"
            autocomplete="off" />
        </div>
      </div>

      <TelephoneField
        @setTelephone="setTelephone"
        @setTelephonePin="setTelephoneCode"
        :email.sync="reg_form.email" />

      <EmailField
        @setEmail="setEmail"
        @setEmailPin="setEmailCode"
        :telephone.sync="reg_form.telephone" />

      <div class="registration__password">
        <div class="registration__group">
          <label for="password" class="auth__label">{{ $t("auth.password") }}</label>
          <div
            class="reg__field--block"
            :class="{ error: v$.password.$error }">
            <input
              type="password"
              name="password"
              v-model="reg_form.password"
              id="password"
              class="reg__field"
              autocomplete="off" />
          </div>
          <div v-if="v$.$invalid && v$.password.$error" class="errors">
            <p class="error__text" v-if="!v$.password.required">
              {{ $t("auth.requiredToFill") }}
            </p>
          </div>
        </div>

        <div class="registration__group">
          <label for="rep_password" class="auth__label">{{ $t("auth.repeatPassword") }}</label>
          <div
            class="reg__field--block"
            :class="{ error: v$.rep_password.$error }">
            <input
              type="password"
              name="rep_password"
              v-model="reg_form.rep_password"
              id="rep_password"
              class="reg__field"
              autocomplete="off" />
          </div>
          <div
            v-if="v$.$invalid && v$.rep_password.$error"
            class="errors">
            <p class="error__text" v-if="!v$.rep_password.required">
              {{ $t("auth.requiredToFill") }}
            </p>
            <p
              class="error__text"
              v-if="!v$.rep_password.sameAsPassword">
              {{ $t("auth.passwordsNotSame") }}
            </p>
          </div>
        </div>
      </div>
    </form>

    <footer class="auth__footer">
      <div class="reg__checkbox">
        <label for="reg_check" class="reg__label">
          <div class="reg-checkbox">
            <input
              v-model="agreeOne"
              type="checkbox"
              class="reg--real"
              id="reg_check" />
            <span class="reg--fake"></span>
          </div>
          <div>
            {{ $t("auth.agreeWith") }}
            <router-link to="/conditions" class="reg__link">
              {{ $t("auth.useRules") }}
            </router-link>
            {{ $t("and") }}
            <router-link to="/privacy" class="reg__link">
              {{ $t("auth.confidentPolicy") }}
            </router-link>
            *
          </div>
        </label>
      </div>

      <div class="reg__checkbox mb-60">
        <label for="reg_approval" class="reg__label">
          <div class="reg-checkbox">
            <input
              v-model="agreeTwo"
              type="checkbox"
              class="reg--real"
              id="reg_approval" />
            <span class="reg--fake"></span>
          </div>
          {{ $t("auth.youDataAgreed") }}
        </label>
      </div>

      <div class="auth__footer-btn">
        <button
          type="button"
          class="auth__button w_100-468"
          :disabled="!enabled"
          @click.prevent="clickCreate">
          {{ $t("auth.createAccount") }}
        </button>
      </div>
    </footer>
  </main>
</template>

<script setup>
import TelephoneField from "@/components/Registration/Common/TelephoneField.vue";
import EmailField from "@/components/Registration/Common/EmailField.vue";

import { load as loadRecaptcha } from "recaptcha-v3";
const favoritesModule = useFavorites();

import { reactive, ref, computed, onMounted } from "vue";
import { required, sameAs } from "@vuelidate/validators";
const authStore = useAuth();
const { $eventHub } = useNuxtApp();

const emit = defineEmits(["onRegister"]);

const agreeOne = ref(false);
const agreeTwo = ref(false);
const isTelephoneError = ref(true);
const isEmailError = ref(true);
const recapthcaToken = ref(null);
const recaptcha = ref(null);

const reg_form = reactive({
  name: "",
  surname: "",
  email: "",
  telephone: "",
  password: "",
  rep_password: "",
});
const rules = computed(() =>({
  name: {
    required,
  },
  password: {
    required,
  },
  rep_password: {
    required,
    sameAsPassword: sameAs(reg_form.password),
  },
}));
import { useVuelidate } from "@vuelidate/core";
const v$ = useVuelidate(rules, reg_form);

const confirmCode = reactive({
  email: "",
  telephone: "",
  isEmailCodeError: true,
  isTelephoneCodeError: true,
});

const enabled = computed(() => agreeOne.value && agreeTwo.value);


onMounted(async () => {
  try {
    const recaptchaReponse = authStore.getRecaptchaSiteKey();
    if (recaptchaReponse.use) {
      loadRecaptcha(recaptchaReponse.key).then((recaptchaReponse) => {
        recaptcha.value = recaptchaReponse;
      });
    }
  } catch (e) {
    console.error(e);
  }
});

async function getRecaptcha() {
  return recaptcha.value ? await recaptcha.value.execute("submit") : true;
}
function setEmailCode(value) {
  confirmCode.isEmailCodeError = true;
  confirmCode.email = value.val;
  confirmCode.isEmailCodeError = value.hasError;
}
function setTelephoneCode(value) {
  confirmCode.isTelephoneCodeError = true;
  confirmCode.telephone = value.val;
  confirmCode.isTelephoneCodeError = value.hasError;
}
function setTelephone(value) {
  isTelephoneError.value = true;
  reg_form.telephone = value.val;
  isTelephoneError.value = value.hasError;
  $eventHub.$emit("emailCheck");
}
function setEmail(value) {
  isEmailError.value = true;
  reg_form.email = value.val;
  isEmailError.value = value.hasError;
  $eventHub.$emit("telephoneCheck");
}
async function clickCreate() {
  v$.value.$touch();
  $eventHub.$emit("telephoneCheck");
  $eventHub.$emit("emailCheck");

  if (
    v$.value.$invalid ||
    isTelephoneError.value ||
    confirmCode.isTelephoneCodeError ||
    (isEmailError.value && confirmCode.isEmailCodeError)
  ) {
    return;
  }
  favoritesModule.loadFavoriteSalons();
  const recaptcha = await getRecaptcha();
  emit("onRegister", {
    emailConfirmCode: confirmCode.email,
    phoneConfirmCode: confirmCode.telephone,
    email: reg_form.email,
    phone: reg_form.telephone,
    password: reg_form.password,
    userinfo: {
      firstName: reg_form.name,
      lastName: reg_form.surname,
      status: "Classic",
      balance: 0,
      favoriteSalons: favoritesModule.favoriteSalons,
      favoriteServices: favoritesModule.favoriteServices,
    },
    recapthcaToken: recaptcha,
  });
}

</script>
